import React, { useState, useEffect } from 'react'
import ResultContext from './ResultContext';


function Details(props) {
  const [result, setResult] = useState([]);
  const [result2, setResult2] = useState([]);
  const [result3, setResult3] = useState([]);
  const [my, setMy] = useState({})
  const [dtl, setDtl] = useState({})
  const hostname = process.env.REACT_APP_SERVER;

  const fetchDetails1 = async () => {
    const url = `${hostname}/api/List1/fetchdetails`
    let data = await fetch(url, { method: 'GET', })
    let parsedData = await data.json();
    setResult(parsedData);
  }

  const fetchDetails2 = async () => {
    const url = `${hostname}/api/List2/fetchdetails`
    let data = await fetch(url, { method: 'GET', })
    let parsedData = await data.json();
    setResult2(parsedData);
  }

  const fetchDetails3 = async () => {
    const url = `${hostname}/api/List3/fetchdetails`
    let data = await fetch(url, { method: 'GET', })
    let parsedData = await data.json();
    setResult3(parsedData);
  }
  useEffect(() => {
    fetchDetails1()
  }, [])

  useEffect(() => {
    fetchDetails2()
  }, [])

  useEffect(() => {
    fetchDetails3()
  }, [])



  return (
    <ResultContext.Provider value={{ result, setResult, my, setMy, result2, setResult2, result3, setResult3, dtl, setDtl }}>
      {props.children}
    </ResultContext.Provider>
  )
}

export default Details