import React from 'react'
import "./SideBar.css"
import {Link} from "react-router-dom"
export default function SideBar() {
    return (
        
        <div className="wrapper">
    <nav id="sidebar">
        <div className="sidebar-header">
            <h4>Admin Dashboard</h4>
        </div>

        <ul className="components">
        <li>
               
                <Link className="navbar-brand" to="/">Registration Form</Link>
            </li>

            
         <li>   <Link className="navbar-brand"  to="/List">Registration List </Link></li>  
         <li>   <Link className="navbar-brand"  to="/List2">Follow Up Lead </Link></li> 
         <li>   <Link className="navbar-brand"  to="/List3">Confirm Lead</Link></li> 
         <li>   <Link className="navbar-brand"  to="/Search">Search By Date</Link></li> 
        </ul>
        
        
    </nav>
</div>

    )
}
