import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import ResultContext from '../Context/ResultContext';

function List3(props) {
    const context = useContext(ResultContext);
    const { setDtl, result3, setResult3 } = context;
    const hostname = process.env.REACT_APP_SERVER;
    
    const getDetails = async () => {
        const id = props.id;
        const url = `${hostname}/api/List3/getdetails/${id}`
        const data = await fetch(url, { method: 'GET' });
        const parseData = await data.json();
        setDtl(parseData)
    }

    const deleteList3 = async () => {
        const id = props.id
        const url = `${hostname}/api/List3/delete/${id}`
        await fetch(url, { method: 'DELETE' });
        alert("Deleted Successfully")
        const newResult3 = result3.filter((result3) => { return result3._id !== id })
        setResult3(newResult3);
    }



    return (
        <tr key={props._id}>
            <th scope="row">{props.index + 1}</th>
            <td>{props.DOV}</td>
            <td>{props.Name}</td>
            <td>{props.PhoneNo}</td>
            <td>{props.Email}</td>
            <td>{props.ParentsRemarks}</td>
            <td>{props.Status}</td>
            <td><div className="d-flex justify-content-center">
                <Link to="/List3/Details"><button className="btn btn-primary" type="Details" onClick={getDetails}>Details</button></Link></div>
            </td>
            <td><div className="d-flex justify-content-center">
                <Link to="/List3"><button className="btn btn-primary" type="Delete" onClick={deleteList3}>Delete</button></Link></div>
            </td>
        </tr>
    )
}

export default List3