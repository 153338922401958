import React, { useContext} from 'react'
import { Link } from 'react-router-dom';
import ResultContext from '../Context/ResultContext';



function List(props) {
    const context = useContext(ResultContext)
    const {setMy,setResult2,result2} = context;
    const hostname = process.env.REACT_APP_SERVER;
    const getDetails = async () => {
        const id = props.id;
        const url = `${hostname}/api/List1/getdetails/${id}`
        const data = await fetch(url, { method: 'GET' });
        const parseData = await data.json();
        setMy(parseData)
    }
   
    console.log(hostname);
    const addList2 = async () => {
        const url = `${hostname}/api/List2/newForm`
       const data = await fetch(url,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              DOV : props.DOV , Name: props.Name, DOB: props.DOB, FatherName: props.FatherName, MotherName: props.MotherName, PresentClass: props.PresentClass, PresentSchool: props.PresentSchool, AppliedClass: props.AppliedClass, 
               Street: props.Street, Area: props.Area, City: props.City, PinCode: props.PinCode, PhoneNo: props.PhoneNo, FaxNo: props.FaxNo, Email: props.Email,
              ParentsRemarks: props.ParentsRemarks, Status: props.Status, CounseledAt: props.CounseledAt, CounseledBy: props.CounseledBy, Reference: props.Reference
            })
          });
          alert("Successfully Added")
          const add = await data.json();
          // console.log(add);
          setResult2(result2.concat(add))
       }
   
      return (
        <>
            <tr key = {props.id}>
                <th scope="row">{props.index + 1}</th>
                <td>{props.DOV}</td>
                <td>{props.Name}</td>
                <td>{props.PhoneNo}</td>
                <td>{props.Email}</td>
                <td>{props.ParentsRemarks}</td>
                <td>{props.Status}</td>
        <td><div className="d-flex justify-content-center">
        <Link to="/List/Details"><button className="btn btn-primary" type="Delete" onClick={getDetails}>Details</button></Link></div>
        </td>
        <td><div className="d-flex justify-content-center">
        <Link to="/List2"><button className="btn btn-primary" type="Add" onClick={addList2} >Add</button></Link></div>
        </td>  
            </tr>
        </>

    )
}

export default List