import React, { useContext } from 'react'
import ResultContext from '../Context/ResultContext';
import List from './List';



function RegisteredStudents() {
  const context = useContext(ResultContext);
  const { result } = context;



  return (
    <>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Date of Visit</th>
            <th scope="col">Name of Student</th>
            <th scope="col">Mobile No.</th>
            <th scope="col">EmailID.</th>
            <th scope="col">Remarks</th>
            <th scope="col">Status</th>
            <th scope="col">Full Details</th>
            <th scope="col">Follow up</th>
          </tr>
        </thead>
        <tbody>

        {
          result.map((element, index) => {
            return <List  DOV = {element.DOV}  index={index} Name={element.Name}  DOB={element.DOB}  FatherName={element.FatherName} MotherName={element.MotherName}
              PresentClass={element.PresentClass} PresentSchool={element.PresentSchool} AppliedClass={element.AppliedClass}
              Street={element.Street} Area={element.Area} City={element.City} PinCode={element.PinCode}   PhoneNo={element.PhoneNo}
              FaxNo={element.FaxNo} Email={element.Email}  ParentsRemarks={element.ParentsRemarks} Status={element.Status}
              CounseledAt={element.CounseledAt} CounseledBy={element.CounseledBy} Reference={element.Reference} id={element._id} key = {element._id} />   
})
        }
     
     </tbody>
      </table>


    </>
  )
}

export default RegisteredStudents