import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import ResultContext from '../Context/ResultContext';

function List2(props) {
    const context = useContext(ResultContext);
    const { result2, setResult2,setResult3,result3 } = context;
    const hostname = process.env.REACT_APP_SERVER;


    const deletelist2 = async () => {
       const id = props.id

        const url = `${hostname}/api/List2/delete/${id}`
        await fetch(url, { method: 'DELETE' });
        alert("Deleted Successfully")
        const newResult2 = result2.filter((result2) => { return result2._id !== id })
        setResult2(newResult2);
    }

        const addList3 = async () => {
            const url = `${hostname}/api/List3/newForm`
           const data = await fetch(url,
              {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  DOV : props.DOV , Name: props.Name, DOB: props.DOB, FatherName: props.FatherName, MotherName: props.MotherName, PresentClass: props.PresentClass, PresentSchool: props.PresentSchool, AppliedClass: props.AppliedClass, 
                   Street: props.Street, Area: props.Area, City: props.City, PinCode: props.PinCode, PhoneNo: props.PhoneNo, FaxNo: props.FaxNo, Email: props.Email,
                  ParentsRemarks: props.ParentsRemarks, Status: props.Status, CounseledAt: props.CounseledAt, CounseledBy: props.CounseledBy, Reference: props.Reference
                })
              });
              alert("Successfully Added")
              const add = await data.json();
              console.log(add);
              setResult3(result3.concat(add))
           }
    
    return (
        <tr key={props._id}>
            <th scope="row">{props.index + 1}</th>
            <td>{props.DOV}</td>
            <td>{props.Name}</td>
            <td>{props.PhoneNo}</td>
            <td>{props.Email}</td>
            <td>{props.ParentsRemarks}</td>
            <td>{props.Status}</td>
            <td><div className="d-flex justify-content-center">
                <Link to="/List3"><button className="btn btn-primary" type="Add" onClick = {addList3} >Add</button></Link></div>
            </td>
            <td><div className="d-flex justify-content-center">
                <Link to="/List2"><button className="btn btn-primary" type="Delete" onClick={deletelist2}>Delete</button></Link></div>
            </td>
        </tr>  
    )
}

export default List2