import React, { useState,useContext } from 'react';
import { Link } from 'react-router-dom';
import ResultContext from '../Context/ResultContext';

function Search() {
    const context = useContext(ResultContext);
    const { setDtl } = context;

    const [search, setSearch] = useState([])
    const [isChecked1, setIsChecked1] = useState(false)
    const [isChecked2, setIsChecked2] = useState(false)
    const [isChecked3, setIsChecked3] = useState(false)
    const [list, setList] = useState("")
    const [date,setDate] = useState("")

    const getDetails = async (id) => {

        const url = `http://localhost:5000/api/${list}/getdetails/${id}`
        const data = await fetch(url, { method: 'GET' });
        const parseData = await data.json();
        // console.log(parseData);
        setDtl(parseData);
        
    }

    const searchL1 = async () => {
        const url = `http://localhost:5000/api/${list}/findByDate?DOV=${date}`
        const response = await fetch(url, { method: 'GET' });
        const data = await response.json();
        setSearch(data);
    }
    const handleDate=(eve)=>
    {
     setDate(eve.target.value);
     console.log(date);
    }


    const checkbox1 = (event) => {
        setIsChecked1(event.target.checked)
        if(isChecked1===false)
        {
        setList(event.target.value)
        }
        console.log(list)

    }

    const checkbox2 = (e) => {
        setIsChecked2(e.target.checked)
        if(isChecked2===false)
        {
        setList(e.target.value)
    }
    
        console.log(list)
    }


    const checkbox3 = (ev) => {
        setIsChecked3(ev.target.checked)
        if(isChecked3===false)
        {
        setList(ev.target.value)
    }

    }

    return (
        <div>
            <label className="form-label">Search By Date</label>

            <label className="form-check-label mx-2" htmlFor="checkbox" ><strong>List1</strong>
                <input className="form-check-input" type="checkbox" onChange={checkbox1} value="List1" checked={isChecked1} /></label>
            <label className="form-check-label mx-2" htmlFor="checkbox"  ><strong>List2</strong>
                <input className="form-check-input" type="checkbox" onChange={checkbox2} value="List2" checked={isChecked2} /></label>
            <label className="form-check-label mx-2" htmlFor="checkbox"  ><strong>List3</strong>
                <input className="form-check-input" type="checkbox" onChange={checkbox3} value="List3" checked={isChecked3} /></label>

            <input className="form-control" type="text" onChange={handleDate} required = "true" placeholder="dd-mm-yyyy" />
            <button className="btn btn-primary my-3" onClick={searchL1}  type='Search' > Search </button>

            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Date of Visit</th>
                        <th scope="col">Name of Student</th>
                        <th scope="col">Mobile No.</th>
                        <th scope="col">EmailID.</th>
                        <th scope="col">Remarks</th>
                        <th scope="col">Status</th>
                        <th scope="col">Details</th>
                        <th scope="col">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {

                        search.map((element, index) => {
                            return <tr key={element._id}>
                                <th scope="row">{index + 1}</th>
                                <td>{element.DOV}</td>
                                <td>{element.Name}</td>
                                <td>{element.PhoneNo}</td>
                                <td>{element.Email}</td>
                                <td>{element.ParentsRemarks}</td>
                                <td>{element.Status}</td>
                                <td><div className="d-flex justify-content-center">
                                    <Link to="/SearchDetails"><button className="btn btn-primary" type="Details" onClick={() => getDetails(element._id)} >Details</button></Link></div>
                                </td>
                                {/* <td><div className="d-flex justify-content-center">
                                    <Link to="/Search"><button className="btn btn-primary" type="Delete" >Delete</button></Link></div>
                                </td> */}
                            </tr>
                        })
                    }
                </tbody>
            </table>



        </div>

    )
}

export default Search