import React,{useState,useContext} from 'react'
import ResultContext from '../Context/ResultContext';

function SearchedDetails() {
    const [b, setB] = useState({})
    const context = useContext(ResultContext);
    const { dtl } = context;
    setB(dtl)
  return (
    <div>
    <div className="form-group row">
        <label className="col-sm col-form-label">DATE OF VISIT</label>
        <div className="col-sm-10">
          <input className="form-control-plaintext" placeholder={b.DOV} />
        </div>
      </div>

      {/* 2 */}
      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }} >
        <label className="col-sm-2 col-form-label">NAME OF STUDENT</label>
        <div className="col-sm-10">
          <label className="form-control-plaintext" >{b.name}</label>
        </div>
      </div>

      {/* 3 */}

      <div className="form-group row" >
        <label className="col-sm-2 col-form-label">DATE OF BIRTH</label>
        <div className="col-sm-10">
          <label className="form-control-plaintext">{b.DOB}</label>
        </div>
      </div>

      {/* 4 */}

      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }}>
        <label className="col-sm-2 col-form-label">FATHER'S NAME</label>
        <div className="col-sm-10">
          <label className="form-control-plaintext" >{b.FatherName}</label>
        </div>
      </div>

      {/* 5 */}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">MOTHER'S NAME</label>
        <div className="col-sm-10">
          <label className="form-control-plaintext" >{b.MotherName}</label>
        </div>
      </div>

      {/* 6 */}

      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }}>
        <label className="col-sm-2 col-form-label">PRESENT CLASS</label>
        <div className="col-sm-10">
          <label className="form-control-plaintext" >{b.PresentClass}</label>
        </div>
      </div>

      {/* 7 */}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">APPLIED CLASS</label>
        <div className="col-sm-10">
          <label className="form-control-plaintext" >{b.AppliedClass}</label>
        </div>
      </div>

      {/* 8 */}

      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }}>
        <label className="col-sm-2 col-form-label">PRESENT SCHOOL NAME</label>
        <div className="col-sm-10">
          <label className="form-control-plaintext">{b.PresentSchool}</label>
        </div>
      </div>


      {/* 9 */}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">CORRESPONDENCE ADDRESS</label>
        <div className="col-sm-10" >
          <label className="form-control-plaintext my-1" style={{ backgroundColor: "#c1e09a" }}>Street : {b.Street}</label>
          <label className="form-control-plaintext my-1" style={{ backgroundColor: "#c1e09a" }}>Area : {b.Area}</label>
          <label className="form-control-plaintext my-1" style={{ backgroundColor: "#c1e09a" }}>City : {b.City}</label>
          <label className="form-control-plaintext my-1" style={{ backgroundColor: "#c1e09a" }} >Pin Code :{ b.PinCode}</label>
          <label className="form-control-plaintext my-1" style={{ backgroundColor: "#c1e09a" }} required="true">Mobile No. : {b.MobileNo}</label>
          <div className='row'>
            <div className='col'>
              <label className="form-control-plaintext my-1" style={{ backgroundColor: "#c1e09a" }}>Fax no. : {b.FaxNo}</label>
            </div>
            <div className='col'>
              <label className="form-control-plaintext my-1" style={{ backgroundColor: "#c1e09a" }}>Email : {b.Email}</label>
            </div></div>
        </div>
      </div>

      {/* 10 */}

      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }}>
        <label className="col-sm-2 col-form-label">PARENT'S REAMARKS</label>
        <div className="col-sm-10">
          <label className="form-control-plaintext" >{b.ParentsRemarks}</label>
        </div>
      </div>

      {/* 11 */}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">STATUS(REG/ADM)</label>
        <div className="col-sm-10">
          <label className="form-control-plaintext" >{b.Status}</label>
        </div>
      </div>

      {/* 12 */}
      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }}>
        <label className="col-sm-2 col-form-label">COUNSELED AT</label>
        <div className="col-sm-10">
          <label className="form-control-plaintext"  >{b.CounseledAt}</label>
        </div>
      </div>

      {/* 13 */}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">COUNSELED BY</label>
        <div className="col-sm-10">
          <label className="form-control-plaintext"> {b.CounseledBy}</label>
        </div>
      </div>

      {/* 14 */}

      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }}>
        <label className="col-sm-2 col-form-label">REFERENCE</label>
        <div className="col-sm-10">
          <label className="form-control-plaintext" >{b.Reference}</label>
        </div>
        <div className="d-flex justify-content-center">
        </div>
      </div>


    </div>
  )
}

export default SearchedDetails