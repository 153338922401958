import React, { useState, useContext } from 'react'
import ResultContext from '../Context/ResultContext';


function Form() {
  const context = useContext(ResultContext);
  const {setResult } = context;
  const hostname = process.env.REACT_APP_SERVER;
  console.log(hostname)

  const [details, setDetails] = useState({
    DOV : "", Name: "", DOB: "", FatherName: "", MotherName: "", PresentClass: "", PresentSchool: "", AppliedClass: "",
    ParentsRemarks: "", Status: "", CounseledAt: "", CounseledBy: "", Reference: "" ,
   Street: "", Area: "", City: "", PinCode: "", PhoneNo: "", FaxNo: "", Email: ""
  })
 
  const onChange = (e) => {
    setDetails({...details,[e.target.name]: e.target.value})
    // console.log(details)
  }
 


  const submitDetails = async (event) => {
    event.preventDefault()
    const url = `${hostname}/api/List1/newForm`
   const data = await fetch(url,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' ,
        "Accept": "application/json; odata=verbose"
      },
        body: JSON.stringify({
          DOV : details.DOV , Name: details.Name, DOB: details.DOB, FatherName: details.FatherName, MotherName: details.MotherName, PresentClass: details.PresentClass, PresentSchool: details.PresentSchool, AppliedClass: details.AppliedClass, 
           Street: details.Street, Area: details.Area, City: details.City, PinCode: details.PinCode, PhoneNo: details.PhoneNo, FaxNo: details.FaxNo, Email: details.Email,
          ParentsRemarks: details.ParentsRemarks, Status: details.Status, CounseledAt: details.CounseledAt, CounseledBy: details.CounseledBy, Reference: details.Reference
        })
      });
      alert("Form Submitted Successfully")
      window.location.reload();
      const result = await data.json();
      // setResult (result.concat(result));
   }



  return (
    <form onSubmit={submitDetails}>
      {/* 1 */}

      <div div className="form-group row">
        <label className="col-sm col-form-label">DATE OF VISIT</label>
        <div className="col-sm-10">
          <input type="date" className="form-control-plaintext" placeholder='dd/mm/yy' name="DOV" onChange={onChange} />
        </div>
      </div>

      {/* 2 */}
      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }} >
        <label className="col-sm-2 col-form-label">NAME OF STUDENT</label>
        <div className="col-sm-10">
          <input type="text" className="form-control-plaintext" name="Name" onChange={onChange}  required = "true"/>
        </div>
      </div>

      {/* 3 */}

      <div className="form-group row" >
        <label className="col-sm-2 col-form-label">DATE OF BIRTH</label>
        <div className="col-sm-10">
          <input type="date" className="form-control-plaintext" name="DOB" placeholder='dd/mm/yy' onChange={onChange} required = "true" minLength={5}/>
        </div>
      </div>

      {/* 4 */}

      <div className="form-group row"style={{ backgroundColor: "#c1e09a" }} >
        <label className="col-sm-2 col-form-label">FATHER'S NAME</label>
        <div className="col-sm-10">
          <input type="text" className="form-control-plaintext" name="FatherName" onChange={onChange}  required = "true"/>
        </div>
      </div>

      {/* 5 */}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">MOTHER'S NAME</label>
        <div className="col-sm-10">
          <input type="text" className="form-control-plaintext" name="MotherName" onChange={onChange} />
        </div>
      </div>

      {/* 6 */}

      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }} >
        <label className="col-sm-2 col-form-label">PRESENT CLASS</label>
        <div className="col-sm-10">
          <input type="text" className="form-control-plaintext" name="PresentClass" onChange={onChange}  />
        </div>
      </div>

      {/* 7 */}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">APPLIED CLASS</label>
        <div className="col-sm-10">
          <input type="text" className="form-control-plaintext" name="AppliedClass" onChange={onChange} required = "true"/>
        </div>
      </div>

      {/* 8 */}

      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }} >
        <label className="col-sm-2 col-form-label">PRESENT SCHOOL NAME</label>
        <div className="col-sm-10">
          <input type="text" className="form-control-plaintext" name='PresentSchool' onChange={onChange}  />
        </div>
      </div>


      {/* 9 */}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">CORRESPONDENCE ADDRESS</label>
        <div className="col-sm-10" >
          <input type="text" className="form-control-plaintext my-1" name="Street" onChange={onChange} placeholder="Door no street" style={{ backgroundColor: "#c1e09a" }} />
          <input type="text" className="form-control-plaintext my-1" name="Area" onChange={onChange} placeholder="Area" style={{ backgroundColor: "#c1e09a" }} />
          <input type="text" className="form-control-plaintext my-1" name="City" onChange={onChange} placeholder="City" style={{ backgroundColor: "#c1e09a" }} />
          <input type="text" className="form-control-plaintext my-1" name="PinCode" onChange={onChange} placeholder="Pincode" style={{ backgroundColor: "#c1e09a" }} required = "true" />
        <input type="number" className="form-control-plaintext my-1" name="PhoneNo" onChange={onChange} placeholder="Phone/Mobile no." style={{ backgroundColor: "#c1e09a" }} required= "true" />
          <div className='row'>
            <div className='col'>
             <input type="text" className="form-control-plaintext my-1" name="FaxNo" onChange={onChange} placeholder="Fax no." style={{ backgroundColor: "#c1e09a" }} />
             </div>
             <div className='col'>
          <input type="Email" className="form-control-plaintext my-1" name="Email" onChange={onChange} placeholder="Email" style={{ backgroundColor: "#c1e09a" }} />
          </div></div>
        </div>
      </div>

      {/* 10 */}

      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }}>
        <label className="col-sm-2 col-form-label">PARENT'S REAMARKS</label>
        <div className="col-sm-10">
          <input type="text" className="form-control-plaintext" name='ParentsRemarks' onChange={onChange} />
        </div>
      </div>

      {/* 11 */}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">STATUS(REG/ADM)</label>
        <div className="col-sm-10">
          <input type="text" className="form-control-plaintext" name='Status' onChange={onChange} />
        </div>
      </div>

      {/* 12 */}
      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }}>
        <label className="col-sm-2 col-form-label">COUNSELED AT</label>
        <div className="col-sm-10">
          <input type="text" className="form-control-plaintext" name='CounseledAt' onChange={onChange} />
        </div>
      </div>

      {/* 13 */}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">COUNSELED BY</label>
        <div className="col-sm-10">
          <input type="text" className="form-control-plaintext" name='CounseledBy' onChange={onChange} />
        </div>
      </div>

      {/* 14 */}

      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }}>
        <label className="col-sm-2 col-form-label">REFERENCE</label>
        <div className="col-sm-10">
          <input type="text" className="form-control-plaintext" name='Reference' onChange={onChange} />
        </div>
      </div>
      <div className = "d-flex justify-content-center">
      <button type="submit" className="btn btn-dark btn-lg my-3 ">Submit</button></div>

    </form>


  )
}

export default Form