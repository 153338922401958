import React from "react";
import Form from './Component/Form';
import Header from './Component/Header';
import SideBar from './Component/SideBar';
import Details from './Context/Details';
import "./App.css"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import StudentData from "./Component/StudentData";
import RegisteredStudents from "./Component/RegisteredStudents";
import Mapping3 from "./Component/Mapping3";
import Mapping2 from "./Component/Mapping2";
import Update3 from "./Component/Update3";
import Search from "./Component/Search";
import SearchedDetails from "./Component/SearchedDetails";

function App() {
  return (
    <>
      <Router>
        <SideBar />
        <div className="container">
          <Header />
          <Details>
            <Routes>
              <Route exact path="/" element={<Form />} ></Route>
              <Route exact path="/List" element={<RegisteredStudents />} />
              <Route exact path="/List/Details" element={<StudentData />} ></Route>
              <Route exact path="/List2" element={<Mapping2 />} ></Route>
              <Route exact path="/List3" element={<Mapping3 />} ></Route>
              <Route exact path="/List3/Details" element={<Update3 />} ></Route>
              <Route exact path="/Search" element={<Search />} ></Route>
              <Route exact path="/SearchDetails" element={<SearchedDetails />} ></Route>
            </Routes>
          </Details>
        </div>
      </Router>

    </>
  );
}

export default App;
