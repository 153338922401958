import React from 'react'
import logo from "./logo.png"
import "../App.css"

function Header() {
  return (
    <div>
      <div className='logo'><img src={logo} alt="logo" style={{width : "170px" , position : "relative" , left : "3vw"}}/></div>
      <div className='text-center' style={{ backgroundColor: "#c1e09a" }}>

        <h1 > SCHOOL NAME</h1>
        <h3><strong>Affilated to CBSE New Delhi</strong></h3>
        <p style={{ lineHeight: "0.5" }}>(Managed By : The Indian Public School Education Society)</p>
        <p style={{ lineHeight: "0.5" }}> Premnagar,Dehradun - 248007 </p>
        <p style={{ lineHeight: "0.5" }}>(0135-2694XXX/XXX/XXX/XXX)</p>

        <p><strong>VISITOR FORM/ENQUIRY FORM</strong></p>


      </div>

    </div>

  )
}

export default Header