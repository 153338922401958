import React, { useContext, useState } from 'react'
import ResultContext from '../Context/ResultContext';
import { Link } from "react-router-dom";



function StudentData(props) {
  const [details, setDetails] = useState({
    DOV : "", Name: "", DOB: "", FatherName: "", MotherName: "", PresentClass: "", PresentSchool: "", AppliedClass: "",
    ParentsRemarks: "", Status: "", CounseledAt: "", CounseledBy: "", Reference: "" ,Street: "", Area: "", City: "", PinCode: "", PhoneNo: "", FaxNo: "", Email: ""})

     

  const context = useContext(ResultContext);
  const { result, setResult, my ,setMy ,} = context;
  const b = my ;
  
 
 
  
 
  const id = b._id ;


  const deleteDetails = async () => {

    const url = `http://localhost:5000/api/form/delete/${id}`
    await fetch(url, { method: 'DELETE' });
    alert("Deleted Successfully")
    const newResult = result.filter((result) => { return result._id !== id })
    setResult(newResult);
  }
  const updateDetails = async () => {

    const url = `http://localhost:5000/api/form/update/${id}`
   const data = await fetch(url,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          DOV : details.DOV , Name: details.Name, DOB: details.DOB, FatherName: details.FatherName, MotherName: details.MotherName, PresentClass: details.PresentClass, PresentSchool: details.PresentSchool, AppliedClass: details.AppliedClass, 
          Street: details.Street, Area: details.Area, City: details.City, PinCode: details.PinCode, PhoneNo: details.PhoneNo, FaxNo: details.FaxNo, Email: details.Email ,
          ParentsRemarks: details.ParentsRemarks, Status: details.Status, CounseledAt: details.CounseledAt, CounseledBy: details.CounseledBy, Reference: details.Reference })
      });
      const json = data.json();
      alert("Form Updated Successfully");
      console.log(json);
      setMy(json)
      window.location.reload();
  }

  const onChange = (e) => {
    setDetails({...details,[e.target.name]: e.target.value})
  }


  return (
    <>
      {/* 1 */}


      <div className="form-group row">
        <label className="col-sm col-form-label">DATE OF VISIT</label>
        <div className="col-sm-10">
          <input className="form-control-plaintext"  name="DOV" onChange={onChange} value={b.DOV} />
        </div>
      </div>

      {/* 2 */}
      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }} >
        <label className="col-sm-2 col-form-label">NAME OF STUDENT</label>
        <div className="col-sm-10">
          <input className="form-control-plaintext"  name="Name"onChange={onChange} value={b.Name} />
        </div>
      </div>

      {/* 3 */}

      <div className="form-group row" >
        <label className="col-sm-2 col-form-label">DATE OF BIRTH</label>
        <div className="col-sm-10">
          <input className="form-control-plaintext"  name="DOB" onChange={onChange}placeholder={b.DOB} />
        </div>
      </div>

      {/* 4 */}

      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }}>
        <label className="col-sm-2 col-form-label">FATHER'S NAME</label>
        <div className="col-sm-10">
          <input className="form-control-plaintext"   name="FatherName"onChange={onChange} placeholder={b.FatherName} />
        </div>
      </div>

      {/* 5 */}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">MOTHER'S NAME</label>
        <div className="col-sm-10">
          < input className="form-control-plaintext"  name="MotherName"onChange={onChange} placeholder={b.MotherName} />
        </div>
      </div>

      {/* 6 */}

      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }}>
        <label className="col-sm-2 col-form-label">PRESENT CLASS</label>
        <div className="col-sm-10">
          < input className="form-control-plaintext"  name="PresentClass" onChange={onChange}placeholder={b.PresentClass} />
        </div>
      </div>

      {/* 7 */}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">APPLIED CLASS</label>
        <div className="col-sm-10">
          < input className="form-control-plaintext"  name="AppliedClass" onChange={onChange}placeholder={b.AppliedClass} />
        </div>
      </div>

      {/* 8 */}

      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }}>
        <label className="col-sm-2 col-form-label">PRESENT SCHOOL NAME</label>
        <div className="col-sm-10">
          < input className="form-control-plaintext"  name="PresentClass"onChange={onChange} placeholder={b.PresentSchool} />
        </div>
      </div>


      {/* 9 */}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">CORRESPONDENCE ADDRESS</label>
        <div className="col-sm-10" >
          < input className="form-control-plaintext my-1" style={{ backgroundColor: "#c1e09a" }} onChange={onChange} name="Street"  placeholder={b.Street} />
          < input className="form-control-plaintext my-1" style={{ backgroundColor: "#c1e09a" }} onChange={onChange} name="Area"  placeholder={b.Area} />
          < input className="form-control-plaintext my-1" style={{ backgroundColor: "#c1e09a" }} onChange={onChange} name="City"  placeholder={b.City} />
          <input className="form-control-plaintext my-1" style={{ backgroundColor: "#c1e09a" }}  onChange={onChange} name="PinCode" placeholder={b.PinCode} />
          < input className="form-control-plaintext my-1" style={{ backgroundColor: "#c1e09a" }} onChange={onChange} name="PhoneNo"  placeholder={b.PhoneNo} />
          <div className='row'>
            <div className='col'>
              <input className="form-control-plaintext my-1" style={{ backgroundColor: "#c1e09a" }} onChange={onChange} name="FaxNo" placeholder={b.FaxNo} />
            </div>
            <div className='col'>
              < input className="form-control-plaintext my-1" style={{ backgroundColor: "#c1e09a" }} onChange={onChange} name="Email"  placeholder={b.Email} />
            </div></div>
        </div>
      </div>

      {/* 10 */}

      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }}>
        <label className="col-sm-2 col-form-label">PARENT'S REAMARKS</label>
        <div className="col-sm-10">
          < input className="form-control-plaintext" onChange={onChange} name="ParentsRemarks" placeholder={b.ParentsRemarks} />
        </div>
      </div>

      {/* 11 */}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">STATUS(REG/ADM)</label>
        <div className="col-sm-10">
          < input className="form-control-plaintext" onChange={onChange} name="Status" placeholder={b.Status} />
        </div>
      </div>

      {/* 12 */}
      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }}>
        <label className="col-sm-2 col-form-label">COUNSELED AT</label>
        <div className="col-sm-10">
          < input className="form-control-plaintext" onChange = {onChange}  name="CounseledAt"  placeholder={b.CounseledAt} />
        </div>
      </div>

      {/* 13 */}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">COUNSELED BY</label>
        <div className="col-sm-10">
          <input className="form-control-plaintext" onChange = {onChange} name="CounseledBy" placeholder={b.CounseledBy} />
        </div>
      </div>

      {/* 14 */}

      <div className="form-group row" style={{ backgroundColor: "#c1e09a" }}>
        <label className="col-sm-2 col-form-label">REFERENCE</label>
        <div className="col-sm-10">
          <input className="form-control-plaintext" onChange={onChange} name="Reference"  placeholder={b.Reference}></input>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <Link to="/List">   <button className="btn btn-primary" type="Delete" onClick={deleteDetails}>Delete Entry</button></Link>
       <Link to ="/List"> <button className="btn btn-primary" type="Update" onClick={updateDetails}>Update Details</button></Link>
      </div>
     </>
  )
}

export default StudentData


